///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { CustomInput, CloseButton } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const WishlistItem = ({ currency, product, putItem, deleteItem }) => {
  // State
  const [quantity, setQuantity] = useState(product.quantity);

  // onChange functions
  const onQuantityChange = e => {
    try {
      const req = async _ => {
        const q = e.target.value;
        if (q < 0) await deleteItem(product._id);
        else await putItem({ ...product, quantity: q });
      };

      req();
    } catch (e) {}
  };

  // Lifecycle hooks
  useEffect(() => {
    setQuantity(product.quantity);
  }, [product.quantity]);

  // JSX
  return (
    <>
      <td>{product.productName}</td>
      <td>
        {currency}
        {product.price}
      </td>
      <td>
        <CustomInput type='number' value={quantity} onChange={onQuantityChange} />
      </td>
      <td>
        <CloseButton onClick={_ => deleteItem(product._id)} />
      </td>
    </>
  );
};

export default WishlistItem;
