///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/product
export const getProductsSuccess = products => ({ type: types.GET_PRODUCTS_SUCCESS, products });
export const getProductsFailure = errorMessage => ({ type: types.GET_PRODUCTS_FAILURE, errorMessage });
export const getProducts = endpoint => async dispatch => {
  try {
    const res = await api.get(!endpoint ? `${resolveHost()}/api/product` : `${resolveHost()}/api/product${endpoint}`);

    dispatch(getProductsSuccess(res.data));
  } catch (e) {
    dispatch(getProductsFailure(e.message));
  }
};

// POST /api/product
export const postProductSuccess = product => ({ type: types.POST_PRODUCT_SUCCESS, product });
export const postProductFailure = errorMessage => ({ type: types.POST_PRODUCT_FAILURE, errorMessage });
export const postProduct = data => async dispatch => {
  try {
    dispatch(setAlert('Creating the product...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/product`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_PRODUCT_SUCCESS));
    dispatch(postProductSuccess(res.data.product));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.POST_PRODUCT_FAILURE));
    dispatch(postProductFailure(e.message));
  }
};

// PUT /api/product
export const putProductSuccess = product => ({ type: types.PUT_PRODUCT_SUCCESS, product });
export const putProductFailure = errorMessage => ({ type: types.PUT_PRODUCT_FAILURE, errorMessage });
export const putProduct = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the product...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/product/${data.productId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_PRODUCT_SUCCESS));
    dispatch(putProductSuccess(res.data.product));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_PRODUCT_FAILURE));
    dispatch(putProductFailure(e.message));
  }
};

// DELETE /api/product
export const deleteProductSuccess = product => ({ type: types.DELETE_PRODUCT_SUCCESS, product });
export const deleteProductFailure = errorMessage => ({ type: types.DELETE_PRODUCT_FAILURE, errorMessage });
export const deleteProduct = productId => async dispatch => {
  try {
    dispatch(setAlert('Deleting the product...', false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/product/${productId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_PRODUCT_SUCCESS));
    dispatch(deleteProductSuccess(res.data.product));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.DELETE_PRODUCT_FAILURE));
    dispatch(deleteProductFailure(e.message));
  }
};
