///////////////////////////////////////////////////////////////////////////////////MODULES
import { FormText, Label, FormGroup, FormFeedback, CustomInput } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const Interval = ({ value, onChange }) => (
  <FormGroup>
    <Label>Time interval</Label>
    <CustomInput invalid value={value} onChange={onChange} type='time' />
    <FormFeedback>
      We recommend that you do NOT change this value regularly as it can cause hidden bookings.
    </FormFeedback>
    <FormText>
      This determines the minimum time block for the booking table. The total number of time blocks should fit perfectly
      with the given start and end work hours.
    </FormText>
  </FormGroup>
);

export default Interval;
