///////////////////////////////////////////////////////////////////////////////////MODULES
import { connect } from 'react-redux';
import { useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomButton,
  Label,
  FormGroup,
  Form,
  InputGroup,
  FormText,
  CustomInput,
  TextButton,
  ButtonGroup,
  Button,
} from '@ibiliaze/reactstrap';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { login, register, putUserPassword } from '../../actions/user';
import { confirmEmail } from '../../actions/email';
import { setAlert } from '../../actions/alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const UserForm = ({ toggle, modal, login, register, putUserPassword, confirmEmail, setAlert }) => {
  const [inputs, setInputs] = useState({
    authType: 'Login',
    email: '',
    username: '',
    password: '',
    regKey: '',
    admin: false,
  });

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value });

  // onClick functions
  const onSendConfirmationClick = async _ => await confirmEmail({ email: inputs.email?.toLowerCase() });

  // onSubmit function
  const onSubmit = async e => {
    e.preventDefault();

    try {
      const auth =
        inputs.authType === 'Register' ? register : inputs.authType === 'Reset Password' ? putUserPassword : login;
      await auth({ ...inputs, email: inputs.email?.toLowerCase() });
    } catch (e) {}
  };

  // JSX
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <Form onSubmit={onSubmit}>
        <ModalHeader toggle={toggle}>{inputs.authType}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Email *</Label>
            <CustomInput
              requied
              value={inputs.email}
              onChange={onInputsChange}
              name='email'
              placeholder='user@example.com'
            />
          </FormGroup>
          {inputs.authType !== 'Login' && (
            <FormGroup>
              <Label>One Time Passcode *</Label>
              <InputGroup>
                <CustomInput
                  required
                  name='code'
                  placeholder='One Time Passcode'
                  value={inputs.node}
                  onChange={onInputsChange}
                />
                {inputs.authType !== 'Login' && (
                  <>
                    <CustomButton onClick={onSendConfirmationClick} color='success'>
                      Send Code
                    </CustomButton>
                  </>
                )}
              </InputGroup>
              <FormText>Your One Time Passcode (OTP) can only be used once</FormText>
            </FormGroup>
          )}
          {inputs.authType === 'Register' && (
            <>
              <FormGroup>
                <Label>Name *</Label>
                <CustomInput
                  required
                  value={inputs.username}
                  onChange={onInputsChange}
                  name='username'
                  placeholder='Jonny'
                />
              </FormGroup>

              <FormGroup>
                <Label>I am a ...</Label>{' '}
                <ButtonGroup>
                  <Button
                    size={c.b.s}
                    color='primary'
                    outline
                    onClick={_ => setInputs(c => ({ ...c, admin: false }))}
                    active={!inputs.admin}
                  >
                    {c.cosmetologistType}
                  </Button>
                  <Button
                    size={c.b.s}
                    color='primary'
                    outline
                    onClick={_ => setInputs(c => ({ ...c, admin: true }))}
                    active={inputs.admin}
                  >
                    Admin
                  </Button>
                </ButtonGroup>
              </FormGroup>

              <FormGroup>
                <Label>Register Key *</Label>
                <CustomInput
                  required
                  value={inputs.regKey}
                  onChange={onInputsChange}
                  name='regKey'
                  placeholder='4e92736'
                />
              </FormGroup>
            </>
          )}
          <FormGroup>
            <Label>Password *</Label>
            <CustomInput
              required
              value={inputs.password}
              onChange={onInputsChange}
              name='password'
              type='password'
              placeholder='password'
            />
          </FormGroup>
          <span className='form-label'>
            {inputs.authType === 'Login' ? "Don't have an account? " : 'Already have an account? '}
          </span>
          <TextButton
            onClick={_ => setInputs({ ...inputs, authType: inputs.authType !== 'Login' ? 'Login' : 'Register' })}
          >
            {inputs.authType !== 'Login' ? 'Login' : 'Register'}
          </TextButton>

          <div>
            <span className='form-label'>Forgot your password? </span>
            <TextButton onClick={_ => setInputs({ ...inputs, authType: 'Reset Password' })}>
              Reset your password
            </TextButton>
          </div>
        </ModalBody>
        <ModalFooter>
          <CustomButton type='submit' color='primary' size={c.b.s}>
            {inputs.authType}
          </CustomButton>
          <CustomButton onClick={toggle} size={c.b.s}>
            Cancel
          </CustomButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default connect(null, { login, register, putUserPassword, confirmEmail, setAlert })(UserForm);
