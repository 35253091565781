///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

export const getUserSuccess = payload => ({ type: types.GET_USER_SUCCESS, payload });
export const getUserFailure = errorMessage => ({ type: types.GET_USER_FAILURE, errorMessage });
export const getUser = endpoint => async dispatch => {
  try {
    const res = await api.get(!endpoint ? `${resolveHost()}/api/user` : `${resolveHost()}/api/user${endpoint}`);

    dispatch(getUserSuccess(res.data));
  } catch (e) {
    dispatch(getUserFailure(e.message));
  }
};

export const getUsersSuccess = payload => ({ type: types.GET_USERS_SUCCESS, payload });
export const getUsersFailure = errorMessage => ({ type: types.GET_USERS_FAILURE, errorMessage });
export const getUsers = endpoint => async dispatch => {
  try {
    const res = await api.get(!endpoint ? `${resolveHost()}/api/user/all` : `${resolveHost()}/api/user/all${endpoint}`);

    dispatch(getUsersSuccess(res.data));
  } catch (e) {
    dispatch(getUsersFailure(e.message));
  }
};

// Register
export const registerSuccess = payload => ({ type: types.REGISTER_SUCCESS, payload });
export const registerFailure = errorMessage => ({ type: types.REGISTER_FAILURE, errorMessage });
export const register = data => async dispatch => {
  try {
    dispatch(setAlert('Creating account...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/user`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.REGISTER_SUCCESS));
    dispatch(registerSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.REGISTER_FAILURE));
    dispatch(registerFailure(e.message));
  }
};

// Login
export const loginSuccess = payload => ({ type: types.LOGIN_SUCCESS, payload });
export const loginFailure = errorMessage => ({ type: types.LOGIN_FAILURE, errorMessage });
export const login = data => async dispatch => {
  try {
    dispatch(setAlert('Signing in...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/user/token`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.LOGIN_SUCCESS));
    dispatch(loginSuccess(res.data));
    dispatch(getUser());
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.LOGIN_FAILURE));
    dispatch(loginFailure(e.message));
  }
};

// Logout
export const logoutSuccess = payload => ({ type: types.LOGOUT_SUCCESS, payload });
export const logoutFailure = errorMessage => ({ type: types.LOGOUT_FAILURE, errorMessage });
export const logout = () => async dispatch => {
  try {
    dispatch(setAlert('Signing out...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/user/logout`);

    dispatch(setAlert(res.data.message, false, res.status, types.LOGOUT_SUCCESS));
    dispatch(logoutSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.LOGOUT_FAILURE));
    dispatch(logoutFailure(e.message));
  }
};

// PUT /api/user
export const putUserSuccess = payload => ({ type: types.PUT_USER_SUCCESS, payload });
export const putUserFailure = errorMessage => ({ type: types.PUT_USER_FAILURE, errorMessage });
export const putUser = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the account...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/user`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_USER_SUCCESS));
    dispatch(putUserSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.PUT_USER_FAILURE));
    dispatch(putUserFailure(e.message));
  }
};

// PUT /api/user/password
export const putUserPassword = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the password...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/user/password`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_USER_SUCCESS));
    dispatch(putUserSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.PUT_USER_FAILURE));
    dispatch(putUserFailure(e.message));
  }
};

// DELETE /api/user
export const deleteUserSuccess = payload => ({ type: types.DELETE_USER_SUCCESS, payload });
export const deleteUserFailure = errorMessage => ({ type: types.DELETE_USER_FAILURE, errorMessage });
export const deleteUser = () => async dispatch => {
  try {
    dispatch(setAlert('Deleting the account...', false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/user`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_USER_SUCCESS));
    dispatch(deleteUserSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.DELETE_USER_FAILURE));
    dispatch(deleteUserFailure(e.message));
  }
};
