/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
//////////////////////////////////////////////////////////////////////////////////////////

// GET
export const getItemsSuccess = items => ({ type: types.GET_ITEMS_SUCCESS, items });
export const getItemsFailure = errorMessage => ({ type: types.GET_ITEMS_FAILURE, errorMessage });
export const getItems = () => async dispatch => {
  try {
    const wishlist = JSON.parse(localStorage.getItem('wishlist'));
    dispatch(getItemsSuccess(wishlist.items));
  } catch (e) {
    dispatch(setAlert(e.message, true, null, types.GET_ITEMS_FAILURE));
    dispatch(getItemsFailure(e.message));
  }
};

// POST
export const postItemSuccess = items => ({ type: types.POST_ITEM_SUCCESS, items });
export const postItemFailure = errorMessage => ({ type: types.POST_ITEM_FAILURE, errorMessage });
export const postItem = item => async dispatch => {
  try {
    const wishlist = JSON.parse(localStorage.getItem('wishlist'));
    const currentItem = wishlist.items.find(i => i._id === item._id);
    const updated = !!currentItem
      ? wishlist.items.map(i => {
          if (i._id === item._id) {
            return { ...i, quantity: Number(i.quantity) + 1 };
          } else return i;
        })
      : [...wishlist.items, item];

    localStorage.setItem('wishlist', JSON.stringify({ ...wishlist, items: updated }));
    dispatch(postItemSuccess(updated));
  } catch (e) {
    dispatch(setAlert(e.message, true, null, types.POST_ITEM_FAILURE));
    dispatch(postItemFailure(e.message));
  }
};

// PUT
export const putItemSuccess = items => ({ type: types.PUT_ITEM_SUCCESS, items });
export const putItemFailure = errorMessage => ({ type: types.PUT_ITEM_FAILURE, errorMessage });
export const putItem = item => async dispatch => {
  try {
    const wishlist = JSON.parse(localStorage.getItem('wishlist'));
    const updated = wishlist.items.map(i => {
      if (i._id === item._id) {
        return { ...i, ...item };
      } else return i;
    });
    localStorage.setItem('wishlist', JSON.stringify({ ...wishlist, items: updated }));
    dispatch(putItemSuccess(updated));
  } catch (e) {
    dispatch(setAlert(e.message, true, null, types.PUT_ITEM_FAILURE));
    dispatch(putItemFailure(e.message));
  }
};

// DELETE
export const deleteItemSuccess = items => ({ type: types.DELETE_ITEM_SUCCESS, items });
export const deleteItemFailure = errorMessage => ({ type: types.DELETE_ITEM_FAILURE, errorMessage });
export const deleteItem = id => async dispatch => {
  try {
    const wishlist = JSON.parse(localStorage.getItem('wishlist'));
    const filtered = wishlist.items.filter(i => i._id !== id);
    localStorage.setItem('wishlist', JSON.stringify({ ...wishlist, items: filtered }));
    dispatch(deleteItemSuccess(filtered));
  } catch (e) {
    dispatch(setAlert(e.message, true, null, types.DELETE_ITEM_FAILURE));
    dispatch(deleteItemFailure(e.message));
  }
};

// RESET
export const resetWishlistSuccess = () => ({ type: types.RESET_WISHLIST_SUCCESS });
export const resetWishlistFailure = errorMessage => ({ type: types.RESET_WISHLIST_FAILURE, errorMessage });
export const resetWishlist = () => async dispatch => {
  try {
    localStorage.setItem('wishlist', JSON.stringify({ items: [], total: 0, duration: 0 }));
    dispatch(resetWishlistSuccess());
  } catch (e) {
    dispatch(setAlert(e.message, true, null, types.RESET_WISHLIST_FAILURE));
    dispatch(resetWishlistFailure(e.message));
  }
};
