///////////////////////////////////////////////////////////////////////////////////MODULES
import { CustomToast, Col, Row, CustomToastHeader, CustomToastBody } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
import scroll from '../../utils/scroll';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { TransformY, FadeIn } from '../Layout/Intersect';
//////////////////////////////////////////////////////////////////////////////////////////

const About = () => (
  <section id='about-section' className='page p-t-xl' style={{ zIndex: '10' }}>
    <FadeIn>
      <h1 className='t-align-c'>
        About Your <span className='text-gradient'>Best Experience</span>
      </h1>
    </FadeIn>
    <Row>
      {c.aboutUs.map((content, i) => (
        <Col key={i} md={4} onClick={_ => scroll(document.getElementById('services-section')?.offsetTop)}>
          <TransformY>
            <CustomToast>
              <CustomToastHeader>{content.header} </CustomToastHeader>
              <CustomToastBody>{content.body}</CustomToastBody>
            </CustomToast>
          </TransformY>
        </Col>
      ))}
    </Row>
  </section>
);

export default About;
