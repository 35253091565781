///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { connect } from 'react-redux';
import {
  CustomCarousel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CustomButton,
  CustomInput,
  Form,
  FormGroup,
  InputGroup,
  FormText,
} from '@ibiliaze/reactstrap';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { confirmEmail } from '../../actions/email';
import { postReview } from '../../actions/reviews';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { FadeIn, ScaleIn } from '../Layout/Intersect';
//////////////////////////////////////////////////////////////////////////////////////////

const Reviews = ({ reviews, postReview, confirmEmail }) => {
  // State
  const [inputs, setInputs] = useState({ title: '', email: '', phone: '', code: '', stars: 5, body: '' });
  const [modal, setModal] = useState(false);

  // Toggle functions
  const toggle = _ => setModal(!modal);

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value });

  // onClick functions
  const onSendConfirmationClick = async _ => await confirmEmail({ email: inputs.email });

  // onSubmit function
  const onSubmit = async e => {
    e.preventDefault();
    await postReview({ ...inputs, email: inputs.email?.toLowerCase(), title: `${inputs.title} • ${inputs.stars}⭐` });
  };

  // JSX
  return (
    <section id='reviews-section' className='page p-t-xl p-b-xl'>
      <FadeIn>
        <h1 className='t-align-c'>Reviews</h1>
      </FadeIn>
      <ScaleIn>
        <h4 className='text-gradient t-align-c'>{reviews.length} Reviews</h4>
        <CustomCarousel
          items={reviews}
          fade={c.carousel.fade}
          dark={c.carousel.dark}
          indicators={c.carousel.indicators}
        />
        <hr />
        <div className='t-align-c'>
          <CustomButton color='primary' size={c.b.s} onClick={toggle}>
            Leave a Review
          </CustomButton>
        </div>
      </ScaleIn>

      <Modal isOpen={modal} toggle={toggle}>
        <Form onSubmit={onSubmit}>
          <ModalHeader toggle={toggle}>Leave Your Review</ModalHeader>
          <ModalBody>
            <FormGroup>
              <CustomInput required name='title' placeholder='Name' value={inputs.title} onChange={onInputsChange} />
            </FormGroup>
            <FormGroup>
              <CustomInput required name='email' placeholder='Email' value={inputs.email} onChange={onInputsChange} />
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <CustomInput
                  required
                  name='code'
                  placeholder='One Time Passcode'
                  value={inputs.node}
                  onChange={onInputsChange}
                />
                <CustomButton onClick={onSendConfirmationClick} color='success'>
                  Send Code
                </CustomButton>
              </InputGroup>
              <FormText>Your One Time Passcode (OTP) can only be used once</FormText>
            </FormGroup>
            <FormGroup>
              <CustomInput
                required
                name='phone'
                type='tel'
                placeholder='Mobile'
                value={inputs.phone}
                onChange={onInputsChange}
              />
            </FormGroup>
            <FormGroup>
              <CustomInput
                required
                name='body'
                type='textarea'
                rows='3'
                placeholder='Your Review'
                value={inputs.body}
                onChange={onInputsChange}
              />
            </FormGroup>

            <h2 className='text-gradient t-align-c'>{inputs.stars} ⭐</h2>
            <FormGroup>
              <CustomInput
                name='stars'
                type='range'
                min={0}
                max={5}
                step={0.5}
                value={inputs.stars}
                onChange={onInputsChange}
                style={{ padding: '2rem' }}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <CustomButton type='submit' color='primary' size={c.b.s}>
              Submit
            </CustomButton>
            <CustomButton onClick={toggle} size={c.b.s}>
              Close
            </CustomButton>
          </ModalFooter>
        </Form>
      </Modal>
    </section>
  );
};

const mapStateToProps = state => ({ reviews: state.reviews });

export default connect(mapStateToProps, { confirmEmail, postReview })(Reviews);
