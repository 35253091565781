///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Alert, BookingTable, Spinner, CustomCard, ButtonGroup, Button, Fade } from '@ibiliaze/reactstrap';
import { msToTime, getNow } from '@ibiliaze/time';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getBookings } from '../../actions/bookings';
import { getSettings } from '../../actions/settings';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c, { env } from '../../utils/constants';
import { getTimeoffs, getWorkingHours } from '../../utils/combineSettings';
import scroll from '../../utils/scroll';
import apiRequest from '../../utils/httpRequest';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Wishlist from '../Wishlist/Wishlist';
import ManagementCanvas from '../Booking/ManagementCanvas';
import ManagementHeader from '../Booking/ManagementHeader';
import CancelBookingForm from '../Booking/Buttons';
import Users from '../Booking/Users';
import Paging from '../Layout/Paging';
//////////////////////////////////////////////////////////////////////////////////////////

const BookingsPage = ({ user, bookings, settings, wishlist, getBookings, getSettings }) => {
  // State
  const [inputs, setInputs] = useState({
    paid: 'All',
    attended: 'All',
    paymentType: 'All',
    sortBy: 'bookingTime:desc',
    clientName: '',
    removeStart: '',
    removeEnd: '',
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserSettings, setSelectedSettings] = useState(null);
  const [tab, setTab] = useState('table');
  const [loading, setLoading] = useState(false);
  const [selectedTime, setSelectedTime] = useState(false);
  const [booking, setBooking] = useState(false);
  const [search, setSearch] = useState(false);
  const [wishlistModal, setWishlistModal] = useState(false);
  const [page, setPage] = useState(1);
  const [canvas, setCanvas] = useState(false);
  const [firstDay, setFirstDay] = useState(0);
  const [now, setNow] = useState(getNow(env.REACT_APP_TIMEZONE));

  // Toggle functions
  const toggleCanvas = _ => setCanvas(!canvas);
  const toggleWishlistModal = _ => setWishlistModal(!wishlistModal);

  // Location
  const { search: searchParam } = useLocation();

  // onChange functions
  const onDateChange = midnight => setFirstDay(midnight);
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onSearchClick = _ => setSearch(!search);

  const onUserClick = async u => {
    try {
      setSelectedUser(u);
      const selectedUserSettings = await apiRequest({
        endpoint: 'settings',
        method: 'GET',
        params: `?type=${u?.username}`,
      });
      setSelectedSettings(selectedUserSettings.settings);
      scroll(document.getElementById('bookings-section')?.offsetTop);
    } catch (e) {}
  };

  const onSelectBookingClick = booking => {
    setBooking(booking);
    toggleCanvas();
  };

  const onPageClick = (e, index) => {
    e.preventDefault();
    index < 1 ? setPage(1) : setPage(index);
  };

  const onFieldClick = (time, booking, unbookable) => {
    if (user?.isAuthenticated && !unbookable) {
      setBooking(booking);
      setSelectedTime(!!booking ? booking.bookingTime : time);
      toggleCanvas();
    } else if (!user?.isAuthenticated && !booking && !unbookable) {
      setBooking(false);
      setSelectedTime(time);
      toggleWishlistModal();
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      const req = async () => {
        setLoading(true);

        const sortEndpoint = `&sortBy=${inputs.sortBy}`;
        const userEndpoint = !!selectedUser ? `&username=${selectedUser.username}` : '';
        const paymentFilterEndpoint =
          inputs.paid === 'Paid' ? '&paid=true' : inputs.paid === 'Unpaid' ? '&paid=false' : '';
        const attendedFilterEndpoint =
          inputs.attended === 'Attended' ? '&attended=true' : inputs.attended === 'Unattended' ? '&attended=false' : '';
        const paymentTypeFilterEndpoint =
          inputs.paymentType === 'card'
            ? '&paymentType=card'
            : inputs.paymentType === 'cash'
            ? '&paymentType=cash'
            : '';
        const rangeEndpoint = `&after=${firstDay}&before=${firstDay + 604800000}`;
        const limitEndpoint = `&limit=10&skip=${10 * (page - 1)}`;

        !!selectedUser && (await getSettings(`?type=${selectedUser.username}`));
        await getBookings(
          `?clientName=${inputs.clientName}` +
            sortEndpoint +
            userEndpoint +
            paymentFilterEndpoint +
            attendedFilterEndpoint +
            paymentTypeFilterEndpoint +
            (tab === 'table' ? rangeEndpoint : limitEndpoint)
        );

        setLoading(false);
      };
      req();
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, firstDay, search, inputs.paid, inputs.attended, inputs.paymentType, inputs.sortBy, page, selectedUser]);

  useEffect(() => {
    const req = async _ => {
      try {
        const urlUsername = searchParam.replace('?user=', '');
        const urlUser = user?.users?.find(u => u.username === urlUsername);

        if (!!urlUser) {
          setSelectedUser(urlUser);
          const selectedUserSettings = await apiRequest({
            endpoint: 'settings',
            method: 'GET',
            params: `?type=${urlUser?.username}`,
          });
          setSelectedSettings(selectedUserSettings.settings);
        }
      } catch (e) {}
    };
    req();
  }, [searchParam, user?.users]);

  useEffect(() => {
    const interval = setInterval(() => setNow(Date.now()), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // JSX
  return (
    <div className='below-header' style={{ minHeight: '100vh' }}>
      <h1 className='t-align-c no-m'>Bookings</h1>

      <section className='p-t-m page'>
        {user?.isAuthenticated && (
          <>
            <div className='t-align-c'>
              <ButtonGroup size={c.b.s}>
                <Button color='primary' outline onClick={_ => setTab('table')} active={tab === 'table'}>
                  Table
                </Button>
                <Button color='primary' outline onClick={_ => setTab('bookings')} active={tab === 'bookings'}>
                  Bookings
                </Button>
              </ButtonGroup>
            </div>

            <br />

            {!!selectedUser && canvas && (
              <ManagementCanvas
                canvas={canvas}
                toggle={toggleCanvas}
                booking={booking}
                selectedUser={selectedUser}
                selectedTime={selectedTime}
              />
            )}
          </>
        )}
        <Users selectedUser={selectedUser} users={user.users} onUserClick={onUserClick} />
        <br />
        <CancelBookingForm user={user} selectedUser={selectedUser} />
        <br />
      </section>

      <section className='page' id='bookings-section'>
        {user?.isAuthenticated && !!selectedUser && tab === 'bookings' && (
          <Fade>
            <ManagementHeader onSearchClick={onSearchClick} inputs={inputs} onInputsChange={onInputsChange} />
            <Paging page={page} onPageClick={onPageClick} />
            {loading ? (
              <Spinner color='primary' />
            ) : (
              !!bookings &&
              bookings.map((b, i) => (
                <CustomCard
                  key={i}
                  multiclick={false}
                  title={b.clientName}
                  titleTag='h6'
                  subtitle={b.email}
                  subtitleTag='h6'
                  text={msToTime(b.bookingTime)}
                  onCardClickHandler={_ => onSelectBookingClick(b)}
                  className={
                    now > b.bookingTime && !b.attended
                      ? 'custom-card-red'
                      : now > b.bookingTime && b.attended
                      ? 'clicked-custom-card'
                      : ''
                  }
                />
              ))
            )}
          </Fade>
        )}

        {!!settings && !!settings.global && !!selectedUser && !!selectedUserSettings && tab === 'table' && (
          <Fade className='p-b-m'>
            <Alert color='primary'>
              Please book your appointment after{' '}
              {msToTime(
                now + selectedUserSettings?.earliestBooking || settings.global?.earliestBooking,
                0,
                env.REACT_APP_LOCALE_DATE,
                env.REACT_APP_TIMEZONE
              )}
            </Alert>

            <br />

            <BookingTable
              bookings={bookings}
              workingHours={{
                days: getWorkingHours(settings.global?.workingHours, selectedUserSettings?.workingHours),
                interval: settings.global?.interval,
              }}
              offPeriods={getTimeoffs(settings.global?.timeoffs, selectedUserSettings?.timeoffs)}
              onFieldClickHandler={onFieldClick}
              onDragEndHandler={_ => {}}
              onDateChangeHandler={onDateChange}
              localeDate={env.REACT_APP_LOCALE_DATE}
              timeZone={env.REACT_APP_TIMEZONE}
              bookingTitle={user?.isAuthenticated ? 'clientName' : 'Booked'}
              nextBookingDuration={!user?.isAuthenticated ? wishlist?.duration : 0}
              bookAfter={now + selectedUserSettings?.earliestBooking}
              view={user?.isAuthenticated ? 'table' : 'calendar'}
            />

            <Wishlist
              modal={wishlistModal}
              toggle={toggleWishlistModal}
              selectedUser={selectedUser}
              selectedTime={selectedTime}
            />
          </Fade>
        )}
      </section>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user,
  bookings: state.bookings,
  settings: state.settings,
  wishlist: state.wishlist,
});

export default connect(mapStateToProps, { getBookings, getSettings })(BookingsPage);
