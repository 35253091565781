///////////////////////////////////////////////////////////////////////////////////MODULES
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { CustomButton, Form, ButtonGroup, Button, Badge, Fade } from '@ibiliaze/reactstrap';
import { roundMs, dtlToMs, hmToMs, msToDHM, msToHm } from '@ibiliaze/time';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { putSettings, getSettings, resetSettings } from '../../actions/settings';
import { deleteUser, putUser } from '../../actions/user';
import { setAlert } from '../../actions/alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from '../../utils/httpRequest';
import c from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Banner from '../Settings/Banner';
import Interval from '../Settings/Interval';
import EarliestBooking from '../Settings/EarliestBooking';
import Round from '../Settings/Round';
import WorkingHours from '../Settings/WorkingHours';
import Timeoff from '../Settings/Timeoff';
import Account from '../Settings/Account';
//////////////////////////////////////////////////////////////////////////////////////////

const Settings = ({ user, settings, putSettings, getSettings, resetSettings, deleteUser, putUser, setAlert }) => {
  // State
  const [inputs, setInputs] = useState({
    workingHours: {},
    interval: 0,
    roundBooking: '',
    type: 'global',
    // Banner inputs
    bannerHeader: '',
    bannerBody: '',
    bannerColour: '',
    bannerTextColour: '',
    showBanner: false,
  });
  const [settingsId, setSettingsId] = useState(settings?.global?._id);
  const [earliestBooking, setEarliestBooking] = useState({ days: 0, hours: 0, mins: 0 });
  const [timeoff, setTimeoff] = useState({ timeoffStart: '', timeoffEnd: '' });
  const [timeoffs, setTimeoffs] = useState([]);
  const [tab, setTab] = useState('global');

  // onChange functions
  const onStartTimeChange = (dow, time) =>
    setInputs({
      ...inputs,
      workingHours: { ...inputs.workingHours, [dow]: { ...inputs.workingHours[dow], start: hmToMs(time) } },
    });
  const onEndTimeChange = (dow, time) =>
    setInputs({
      ...inputs,
      workingHours: { ...inputs.workingHours, [dow]: { ...inputs.workingHours[dow], end: hmToMs(time) } },
    });
  const onIntervalChange = e => setInputs({ ...inputs, interval: hmToMs(e.target.value) });
  const onRoundChange = e => setInputs({ ...inputs, roundBooking: e.target.value });
  const onBannerInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.label ? e.value : e.target.value }));
  const onEarliestBookingChange = e => setEarliestBooking({ ...earliestBooking, [e.target.name]: e.target.value });
  const onTimeoffStartChange = e => setTimeoff(c => ({ ...c, timeoffStart: e.target.value }));
  const onTimeoffEndChange = e => setTimeoff(c => ({ ...c, timeoffEnd: e.target.value }));
  const onAvatarChange = async e => {
    try {
      const avatar = e.target.files[0];

      if (!!avatar) {
        setAlert('Uploading avatar...', false, null, null, true);
        const formData = new FormData();
        formData.append('image', avatar);
        const res = await apiRequest({
          endpoint: 'user/upload',
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
        });
        if (res.isError || !res?.secure_url) throw new Error(res?.message || 'Failed to upload image');
        const avatarPath = res?.secure_url;
        await putUser({ avatar: avatarPath });
      }
    } catch (e) {
      setAlert(e.message, true, null, null, false);
    }
  };

  // onClick functions
  const onResetClick = async _ => await resetSettings(`?type=${tab === 'user' ? user?.user?.username : 'global'}`);
  const onAddTimeoffClick = _ => {
    if (timeoff.timeoffStart && timeoff.timeoffEnd) {
      setTimeoffs([
        ...timeoffs,
        {
          start: roundMs(dtlToMs(timeoff.timeoffStart), 'nearest', inputs.interval),
          end: roundMs(dtlToMs(timeoff.timeoffEnd), 'nearest', inputs.interval),
        },
      ]);
    }
  };
  const onRemoveTimeoffClick = index => {
    timeoffs.splice(index, 1);
    setTimeoffs([...timeoffs]);
  };

  // Lifecycle hooks
  useEffect(() => {
    const { days, hours, mins } = msToDHM(settings[tab]?.earliestBooking);
    setSettingsId(settings[tab]?._id);
    setInputs({ ...inputs, ...settings[tab] });
    setEarliestBooking({ days, hours, mins });
    setTimeoffs(settings[tab]?.timeoffs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, tab]);

  useEffect(() => {
    const req = async _ => {
      try {
        if (tab === 'user') {
          await getSettings(`?type=${user?.user?.username}`);
        }
      } catch (e) {}
    };

    req();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  // onSubmit functions
  const onSubmit = async e => {
    e.preventDefault();
    try {
      await putSettings({
        ...inputs,
        type: undefined, // type cannot be updated
        settingsId,
        timeoffs,
        earliestBooking:
          earliestBooking.days * 24 * 3600000 + earliestBooking.hours * 3600000 + earliestBooking.mins * 60000,
      });
    } catch (e) {}
  };

  // JSX
  return (
    <div className='below-header page' style={{ minHeight: '100vh' }}>
      {user?.isAuthenticated && (
        <>
          <h1 className='t-align-c no-m'>Settings</h1>
          <br />

          <div className='t-align-c'>
            <ButtonGroup size={c.b.s}>
              <Button color='primary' outline onClick={_ => setTab('user')} active={tab === 'user'}>
                User
              </Button>
              <Button color='primary' outline onClick={_ => setTab('global')} active={tab === 'global'}>
                Global
              </Button>
            </ButtonGroup>
          </div>

          <br />

          {tab === 'user' && (
            <Fade className='t-align-c'>
              {user?.user?.admin && (
                <h6>
                  Viewing settings as:{' '}
                  <Badge color={user?.user?.admin ? 'primary' : 'secondary'}>
                    {user?.user?.admin ? 'Admin' : 'User'}
                  </Badge>
                </h6>
              )}
            </Fade>
          )}

          <section className='p-t-m'>
            <Form onSubmit={onSubmit}>
              {tab === 'global' && (
                <>
                  <Banner inputs={inputs} onBannerInputsChange={onBannerInputsChange} setBannerInputs={setInputs} />
                  <hr />
                  <br />
                  <h4 className='t-align-c'>Booking</h4>
                  <Round value={inputs.roundBooking} onChange={onRoundChange} />
                  <Interval value={msToHm(inputs.interval)} onChange={onIntervalChange} />
                  <hr />
                  <br />
                </>
              )}
              <WorkingHours
                workingHours={inputs.workingHours}
                onStartTimeChange={onStartTimeChange}
                onEndTimeChange={onEndTimeChange}
              />
              <hr />
              <br />
              <Timeoff
                timeoffs={timeoffs}
                timeoffStart={timeoff.timeoffStart}
                timeoffEnd={timeoff.timeoffEnd}
                onTimeoffStartChange={onTimeoffStartChange}
                onTimeoffEndChange={onTimeoffEndChange}
                onAddClick={onAddTimeoffClick}
                onRemoveClick={onRemoveTimeoffClick}
              />
              {tab === 'user' && (
                <>
                  <hr />
                  <br />
                  <EarliestBooking
                    days={earliestBooking.days}
                    hours={earliestBooking.hours}
                    mins={earliestBooking.mins}
                    onChange={onEarliestBookingChange}
                  />
                  <hr />
                  <br />
                  <Account user={user} deleteUser={deleteUser} onAvatarChange={onAvatarChange} />
                </>
              )}
              <hr />
              <br />
              <CustomButton color='danger' onClick={onResetClick} size={c.b.s}>
                Reset
              </CustomButton>{' '}
              <CustomButton type='submit' color='primary' size={c.b.s}>
                Save
              </CustomButton>
              <br />
              <br />
            </Form>
          </section>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({ user: state.user, settings: state.settings });

export default connect(mapStateToProps, { putSettings, getSettings, resetSettings, deleteUser, putUser, setAlert })(
  Settings
);
