///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  CustomButton,
  InputGroup,
  CustomInputGroupText,
  FormGroup,
  Form,
  Label,
  CustomInput,
  CustomMultiSelect,
} from '@ibiliaze/reactstrap';
import { dtlToMs, roundMs, hmToMs, msToHm, msToDtl, toLocale } from '@ibiliaze/time';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { putBooking, deleteBooking, postBookingAdmin } from '../../actions/bookings';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Actions from './Actions';
//////////////////////////////////////////////////////////////////////////////////////////

const ManagementCanvas = ({
  toggle,
  canvas,
  booking,
  selectedUser,
  selectedTime,
  putBooking,
  postBookingAdmin,
  deleteBooking,
  settings,
  products,
  users,
}) => {
  // State
  const [selected, setSelected] = useState([]);
  const [inputs, setInputs] = useState({
    bookingId: '',
    clientName: '',
    email: '',
    phone: '',
    paid: false,
    attended: false,
    price: 0,
    username: selectedUser.username,
    courses: 1,
    completedCourses: 0,
    bookingTime: '',
    duration: settings?.global?.interval,
    notes: '',
    dob: '',
    paymentType: c.paymentTypes[0],
  });

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.checked || e.target.value });

  // onClick functions
  const onDeleteClick = async _ => {
    try {
      if (!!booking) {
        await deleteBooking(booking._id);
      }
    } catch (e) {}
  };

  // Lifecycle hooks
  useEffect(() => {
    if (!!booking) {
      setInputs(c => ({ ...c, bookingTime: msToDtl(selectedTime) }));
      setSelected([...booking.services.map(s => ({ label: s, value: s }))]);
    } else {
      setInputs(c => ({
        ...c,
        bookingId: '',
        clientName: '',
        email: '',
        phone: '',
        paid: false,
        attended: false,
        price: 0,
        username: selectedUser.username,
        courses: 1,
        completedCourses: 0,
        bookingTime: msToDtl(selectedTime),
        duration: settings?.global?.interval,
        notes: '',
        dob: '',
        paymentType: c.paymentTypes[0],
      }));
      setSelected([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTime]);

  useEffect(() => {
    setInputs(c => ({
      ...c,
      bookingId: booking?._id || '',
      clientName: booking?.clientName || '',
      email: booking?.email || '',
      phone: booking?.phone || '',
      paid: booking?.paid || false,
      attended: booking?.attended || false,
      price: booking?.price || 0,
      username: booking?.username || selectedUser.username,
      courses: booking?.courses || 1,
      completedCourses: booking?.completedCourses || 0,
      bookingTime: (!!booking && msToDtl(booking?.bookingTime)) || msToDtl(selectedTime),
      duration: booking?.duration ? msToHm(booking?.duration) : msToHm(settings?.global?.interval),
      notes: booking?.notes || '',
      dob: booking?.dob || '',
      paymentType: booking?.paymentType || c.paymentTypes[0],
    }));
    setSelected(
      !!booking?.services && booking?.services.length !== 0
        ? [...booking.services.map(s => ({ label: s, value: s }))]
        : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  // onSubmit function
  const onSubmit = async e => {
    e.preventDefault();

    try {
      const payload = {
        ...booking,
        ...inputs,
        email: inputs.email?.toLowerCase(),
        services: selected.map(s => s.value),
        duration: roundMs(hmToMs(inputs.duration), settings?.global?.roundBooking, settings?.global?.interval),
        bookingTime: roundMs(dtlToMs(inputs.bookingTime), 'nearest', settings?.global?.interval),
      };

      const req = !!booking ? putBooking : postBookingAdmin;
      await req({ ...payload });
      toggle();
    } catch (e) {}
  };

  // JSX
  return (
    <Offcanvas fade scrollable isOpen={canvas} toggle={toggle}>
      <OffcanvasHeader toggle={toggle}>Management</OffcanvasHeader>
      <OffcanvasBody>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Label>Booking Time *</Label>
            <CustomInput
              required
              type='datetime-local'
              name='bookingTime'
              value={inputs.bookingTime}
              onChange={onInputsChange}
            />
          </FormGroup>
          <br />
          <h5 className='t-align-c'>Client Details</h5>
          <FormGroup>
            <InputGroup>
              <CustomInputGroupText>{!!booking ? 'EDIT' : 'NEW'}</CustomInputGroupText>
              <CustomInput
                valid={inputs.attended ? true : false}
                required
                placeholder='Client Name'
                name='clientName'
                value={inputs.clientName}
                onChange={onInputsChange}
              />
              <CustomButton
                color={inputs.attended ? 'secondary' : 'primary'}
                onClick={_ => setInputs({ ...inputs, attended: !inputs.attended })}
              >
                {inputs.attended ? 'Unattended' : 'Attended'}
              </CustomButton>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <CustomInput
                required
                placeholder='Email'
                type='email'
                name='email'
                value={inputs.email}
                onChange={onInputsChange}
              />
              <CustomInput
                required
                placeholder='Phone'
                type='tel'
                name='phone'
                value={inputs.phone}
                onChange={onInputsChange}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label>Date of Birth *</Label>
            <CustomInput required name='dob' type='date' value={inputs.dob} onChange={onInputsChange} />
          </FormGroup>
          <br />
          <h5 className='t-align-c'>Service Details</h5>
          <FormGroup>
            <Label>Price *</Label>
            <InputGroup>
              <CustomInputGroupText>{c.currency}</CustomInputGroupText>
              <CustomInput
                valid={inputs.paid ? true : false}
                required
                type='number'
                placeholder='Price'
                name='price'
                value={inputs.price}
                onChange={onInputsChange}
              />
              <CustomButton
                color={inputs.paid ? 'secondary' : 'primary'}
                onClick={_ => setInputs({ ...inputs, paid: !inputs.paid })}
              >
                {inputs.paid ? 'Unpaid' : 'Paid'}
              </CustomButton>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label>Payment Type *</Label>
            <CustomInput
              required
              disabled={c.paymentTypes.length === 1 ? true : false}
              name='paymentType'
              type='select'
              value={inputs.paymentType}
              onChange={onInputsChange}
            >
              {c.paymentTypes.map((pt, i) => (
                <option key={i}>{pt}</option>
              ))}
            </CustomInput>
          </FormGroup>
          <FormGroup>
            <Label>{c.cosmetologistType} *</Label>
            <CustomInput
              required
              type='select'
              placeholder='Johnny'
              name='username'
              value={inputs.username}
              onChange={onInputsChange}
            >
              {!!users && users.map((u, i) => <option key={i}>{u.username}</option>)}
            </CustomInput>
          </FormGroup>
          <FormGroup>
            <Label>Services</Label>
            <CustomMultiSelect
              required
              name='services'
              value={selected}
              onChange={setSelected}
              labelledBy='Select'
              options={[].concat(...products?.map(p => ({ label: p.productName, value: p.productName })))}
            />
          </FormGroup>
          <FormGroup>
            <Label>Duration *</Label>
            <CustomInput required type='time' name='duration' value={inputs.duration} onChange={onInputsChange} />
          </FormGroup>
          <br />
          <h5 className='t-align-c'>Additional Details</h5>
          <FormGroup>
            <Label>Courses</Label>
            <CustomInput
              type='number'
              placeholder='Courses'
              name='courses'
              value={inputs.courses}
              onChange={onInputsChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Completed Courses</Label>
            <CustomInput
              type='number'
              placeholder='Completed Courses'
              name='completedCourses'
              value={inputs.completedCourses}
              onChange={onInputsChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Notes</Label>
            <CustomInput
              placeholder='Notes'
              name='notes'
              type='textarea'
              rows='2'
              value={inputs.notes}
              onChange={onInputsChange}
            />
          </FormGroup>
          {!!booking && (
            <>
              <br />
              <Actions settings={settings} selected={selected} inputs={inputs} />
              <hr />
              <div style={{ fontSize: 'small' }}>
                <div>Booked at:</div>
                {toLocale(booking.createdAt)}
                <br />
                <br />
                <div>Last updated:</div>
                {toLocale(booking.updatedAt)}
                <br />
                <br />
                <div>Booking ID:</div>
                {booking._id}
                <br />
                <br />
              </div>
            </>
          )}
          <CustomButton onClick={onDeleteClick} color='danger' size={c.b.s}>
            Remove
          </CustomButton>{' '}
          <CustomButton type='submit' color='primary' size={c.b.s}>
            Save
          </CustomButton>
          <br />
          <br />
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  );
};

const mapStateToProps = state => ({ settings: state.settings, users: state.user.users, products: state.products });

export default connect(mapStateToProps, { putBooking, postBookingAdmin, deleteBooking })(ManagementCanvas);
