///////////////////////////////////////////////////////////////////////////////////MODULES
import { FormText, Label, FormGroup, CustomInput } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const Round = ({ value, onChange }) => (
  <FormGroup>
    <Label>Round</Label>
    <CustomInput value={value} onChange={onChange} type='select'>
      <option>up</option>
      <option>down</option>
      <option>nearest</option>
    </CustomInput>
    <FormText>
      Up: any minute over the interval will round to the next time block. Down: any minute over the interval will round
      to the down the initial time block. Nearest: will round to the nearest time block.
    </FormText>
  </FormGroup>
);

export default Round;
