///////////////////////////////////////////////////////////////////////////////////MODULES
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
//////////////////////////////////////////////////////////////////////////////////REDUCERS
import user from '../reducers/user';
import bookings from '../reducers/bookings';
import products from '../reducers/products';
import reviews from '../reducers/reviews';
import wishlist from '../reducers/wishlist';
import alert from '../reducers/alert';
import settings from '../reducers/settings';
//////////////////////////////////////////////////////////////////////////////////////////

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const configureStore = () => {
  const store = createStore(
    combineReducers({ user, bookings, products, reviews, wishlist, alert, settings }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};

export default configureStore;
