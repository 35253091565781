///////////////////////////////////////////////////////////////////////////////////MODULES
import { FormGroup, Label, CustomInput, Row, Col } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const Banner = ({ inputs, setBannerInputs, onBannerInputsChange }) => (
  <>
    <h4 className='t-align-c'>Banner</h4>

    <FormGroup>
      <Label>Header</Label>
      <CustomInput
        name='bannerHeader'
        placeholder='Announcement'
        value={inputs.bannerHeader}
        onChange={onBannerInputsChange}
      />
    </FormGroup>

    <FormGroup>
      <Label>Body</Label>
      <CustomInput
        type='textarea'
        name='bannerBody'
        placeholder='This is our announcement'
        value={inputs.bannerBody}
        onChange={onBannerInputsChange}
      />
    </FormGroup>

    <Row>
      <Col md={6}>
        <FormGroup>
          <Label>Colour</Label>
          <CustomInput type='color' name='bannerColour' value={inputs.bannerColour} onChange={onBannerInputsChange} />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label>Text Colour</Label>
          <CustomInput
            type='color'
            name='bannerTextColour'
            value={inputs.bannerTextColour}
            onChange={onBannerInputsChange}
          />
        </FormGroup>
      </Col>
    </Row>

    <FormGroup>
      <CustomInput
        type='switch'
        checked={inputs.showBanner}
        onChange={e => setBannerInputs(c => ({ ...c, showBanner: e.target.checked }))}
      />{' '}
      <Label>Show Banner</Label>
    </FormGroup>
  </>
);

export default Banner;
