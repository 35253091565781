export const getWorkingHours = (globalWorkingHours, userWorkingHours) => {
  try {
    const workingHours = {};
    for (const day in globalWorkingHours) {
      workingHours[day] =
        userWorkingHours[day].start > globalWorkingHours[day].start
          ? { start: userWorkingHours[day].start }
          : { start: globalWorkingHours[day].start };

      workingHours[day] =
        userWorkingHours[day].start === userWorkingHours[day].end ||
        globalWorkingHours[day].start === globalWorkingHours[day].end
          ? { start: 0, end: 0 }
          : userWorkingHours[day].end < globalWorkingHours[day].end
          ? { start: workingHours[day].start, end: userWorkingHours[day].end }
          : { start: workingHours[day].start, end: globalWorkingHours[day].end };
    }
    return workingHours;
  } catch (e) {
    return {
      Mon: { start: 0, end: 0 },
      Tue: { start: 0, end: 0 },
      Wed: { start: 0, end: 0 },
      Thu: { start: 0, end: 0 },
      Fri: { start: 0, end: 0 },
      Sat: { start: 0, end: 0 },
      Sun: { start: 0, end: 0 },
    };
  }
};

export const getTimeoffs = (globalTimeoffs, userTimeoffs) => {
  try {
    return [...globalTimeoffs, ...userTimeoffs];
  } catch (e) {
    return [];
  }
};
