/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
///////////////////////////////////////////////////////////////////////ITEMS DEFAULT STATE
const wishlistDefaultState = { items: [], total: 0, duration: 0 };
localStorage.setItem('wishlist', localStorage.getItem('wishlist') || JSON.stringify(wishlistDefaultState));
/////////////////////////////////////////////////////////////////////////////ITEMS REDUCER
const wishlist = (state = wishlistDefaultState, action) => {
  switch (action.type) {
    // Success
    case types.GET_ITEMS_SUCCESS:
    case types.POST_ITEM_SUCCESS:
    case types.PUT_ITEM_SUCCESS:
    case types.DELETE_ITEM_SUCCESS:
      let total = 0;
      let duration = 0;
      for (const item of action.items) {
        total = total + Number(item.price) * Number(item.quantity);
        duration = duration + Number(item.duration) * Number(item.quantity);
      }
      return { ...state, duration, total, items: [...action.items] };

    case types.GET_ITEMS_FAILURE:
    case types.POST_ITEM_FAILURE:
    case types.PUT_ITEM_FAILURE:
    case types.DELETE_ITEM_FAILURE:
    case types.RESET_WISHLIST_SUCCESS:
    case types.RESET_WISHLIST_FAILURE:
      return { ...wishlistDefaultState };

    default:
      return state;
  }
};

export default wishlist;
