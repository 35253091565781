///////////////////////////////////////////////////////////////////////////////////MODULES
import { FloatingWhatsApp } from 'react-floating-whatsapp';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const ChatWidget = () => (
  <FloatingWhatsApp
    phoneNumber={c.phone1}
    accountName={c.chatPerson}
    statusMessage={c.chatStatus}
    chatboxStyle={{ boxShadow: '0 0 20px rgba(9, 11, 14, 0.5)' }}
    chatMessage={c.chatMessage}
    allowEsc
    allowClickAway
    avatar='/img/faha.jpeg'
    style={{ zIndex: 30 }}
  />
);

export default ChatWidget;
