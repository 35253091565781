///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomButton, Table, Modal, ModalHeader, ModalBody, ModalFooter, Form } from '@ibiliaze/reactstrap';
import { msToHm, roundMs } from '@ibiliaze/time';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { postBooking, postBookingCallback } from '../../actions/bookings';
import { deleteItem, putItem } from '../../actions/wishlist';
import { confirmEmail } from '../../actions/email';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import WishlistItem from './WishlistItem';
import WishlistInputs from './WishlistInputs';
import Callback from './Callback';
//////////////////////////////////////////////////////////////////////////////////////////

const largestCourseCount = products => {
  if (products.length === 0) {
    return null; // Handle the case when the array is empty
  }

  let largestProduct = products[0]; // Initialize with the first product

  for (let i = 1; i < products.length; i++) {
    const currentProduct = products[i];
    if (currentProduct.courses > largestProduct.courses) {
      largestProduct = currentProduct;
    }
  }

  return largestProduct.courses;
};

const Wishlist = ({
  toggle,
  modal,
  selectedTime = '',
  selectedUser,
  wishlist,
  settings,
  postBooking,
  postBookingCallback,
  confirmEmail,
  putItem,
  deleteItem,
}) => {
  // State
  const [inputs, setInputs] = useState({
    clientName: '',
    email: '',
    phone: '',
    code: '',
    notes: '',
    dob: '',
    paymentType: c.paymentTypes[0],
    ppChecked: false,
    // request callback inputs
    desiredCallbackPeriod: c.callback.allowCallback ? c.callback.callbackPeriods[0] : '',
  });
  const [callbackModal, setCallbackModal] = useState(false);

  // Toggle functions
  const toggleCallbackModal = _ => setCallbackModal(!callbackModal);

  // Navigate
  const navigate = useNavigate();

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value });

  // onClick functions
  const onSendConfirmationClick = async _ => await confirmEmail({ email: inputs.email });

  // onSubmit functions
  const onBookSubmit = async e => {
    try {
      e.preventDefault();

      if (callbackModal) {
        /**
         * Callback modal has a different onSubmit function and form, however;
         * since it is the child component of the wishlist form, this onSubmit function will get triggered too.
         * So we prevent this by having this empty if statement
         */
      } else if (selectedTime === '') {
        window.scrollTo(0, 0);
        navigate('/bookings');
      } else {
        const payload = {
          ...inputs,
          username: selectedUser?.username,
          email: inputs.email?.toLowerCase(),
          services: wishlist.items.map(p => p.productName),
          paid: false,
          attended: false,
          price: wishlist.total,
          bookingTime: selectedTime,
          courses: largestCourseCount(wishlist.items),
          duration: roundMs(wishlist.duration, settings?.global?.roundBooking, settings?.global?.interval),
          items: wishlist.items.map(item => ({
            _id: item._id,
            quantity: item.quantity,
          })),
        };
        await postBooking(payload);
      }

      toggle();
    } catch (e) {}
  };
  const onCallbackSubmit = async e => {
    try {
      e.preventDefault();
      await postBookingCallback({
        ...inputs,
        email: inputs.email?.toLowerCase(),
        services: wishlist.items.map(p => p.productName),
      });

      toggle();
    } catch (e) {}
  };

  // Components
  const table = (
    <Table borderless>
      <thead>
        <tr>
          <th>Service</th>
          <th>Price</th>
          <th>Quantity</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {wishlist.items.map((item, i) => (
          <tr key={i}>
            <WishlistItem currency={c.currency} product={item} putItem={putItem} deleteItem={deleteItem} />
          </tr>
        ))}
        <tr>
          <th>Total</th>
          <th>
            {c.currency}
            {wishlist.total}
          </th>
        </tr>
        <tr>
          <th>Duration</th>
          <th>{msToHm(wishlist.duration)}</th>
        </tr>
        {!!selectedUser && (
          <tr>
            <th>{c.cosmetologistType}</th>
            <th>{selectedUser?.username}</th>
          </tr>
        )}
      </tbody>
    </Table>
  );

  // JSX
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <Form onSubmit={onBookSubmit}>
        <ModalHeader toggle={toggle}>Cart</ModalHeader>
        <ModalBody>
          {table}
          <hr />

          {selectedTime !== '' && (
            <WishlistInputs
              selectedTime={selectedTime}
              inputs={inputs}
              setInputs={setInputs}
              onInputsChange={onInputsChange}
              onSendConfirmationClick={onSendConfirmationClick}
              selectedUser={selectedUser}
            />
          )}
        </ModalBody>
        <ModalFooter>
          {c.callback.allowCallback && (
            <>
              <CustomButton onClick={toggleCallbackModal} color='info' size={c.b.s}>
                Request callback
              </CustomButton>
              <Callback
                modal={callbackModal}
                toggle={toggleCallbackModal}
                onSubmit={onCallbackSubmit}
                onSendConfirmationClick={onSendConfirmationClick}
                inputs={inputs}
                setInputs={setInputs}
                onInputsChange={onInputsChange}
              />
            </>
          )}

          <CustomButton
            type='submit'
            disabled={wishlist.items.length === 0 ? true : false}
            color='primary'
            size={c.b.s}
          >
            {selectedTime === '' ? 'Book your time' : 'Make a Booking'}
          </CustomButton>
          <CustomButton onClick={toggle} size={c.b.s}>
            Close
          </CustomButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({ wishlist: state.wishlist, settings: state.settings });

export default connect(mapStateToProps, { postBooking, postBookingCallback, confirmEmail, putItem, deleteItem })(
  Wishlist
);
