/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
////////////////////////////////////////////////////////////////////PRODUCTS DEFAULT STATE
const productsDefaultState = [];
//////////////////////////////////////////////////////////////////////////PRODUCTS REDUCER
const products = (state = productsDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_PRODUCTS_SUCCESS:
      return [...action.products];
    case types.GET_PRODUCTS_FAILURE:
      return [...state];

    // POST request
    case types.POST_PRODUCT_SUCCESS:
      return [...state, action.product];
    case types.POST_PRODUCT_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_PRODUCT_SUCCESS:
      return state.map(product => {
        if (product._id === action.product._id) {
          return { ...product, ...action.product };
        } else {
          return product;
        }
      });
    case types.PUT_PRODUCT_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_PRODUCT_SUCCESS:
      return state.filter(product => product._id !== action.product._id);
    case types.DELETE_PRODUCT_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default products;
