///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Spinner, TextButton } from '@ibiliaze/reactstrap';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from 'chart.js';
import { msToDateNoYr, getNow, msToDay, msToMidnight } from '@ibiliaze/time';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from '../../utils/httpRequest';
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler, Title, Tooltip);

const chartOptions = {
  responsive: true,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

const totalNumbersInitialValue = {
  totalSales: 0,
  totalBookings: 0,
  dailyAvrgSaleAmount: 0,
  newClients: [],
  prevWeekTotalSales: 0,
  prevWeekTotalBookings: 0,
  prevWeekDailyAvrgSaleAmount: 0,
  prevWeekNewClients: [],
};

const InsightsPage = ({ isAuthenticated }) => {
  // State
  const [now] = useState(getNow(process.env.REACT_APP_TIMEZONE));
  const [loading, setLoading] = useState(false);
  const [thisWeek, setThisWeek] = useState([-6, -5, -4, -3, -2, -1, 0]);
  const [salesData, setSalesData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [bookingsData, setBookingsData] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [totalNumbers, setTotalNumbers] = useState(totalNumbersInitialValue);
  const [salesDif, setSalesDif] = useState(0);
  const [bookingsDif, setBookingsDif] = useState(0);
  const [dailyAvrgSaleAmountDif, setDailyAvrgSaleAmountDif] = useState(0);
  const [newClientsDif, setNewClientsDif] = useState(0);

  // Memo
  const data = {
    labels: thisWeek.map(day => msToDay(now, day, process.env.REACT_APP_LOCALE_DATE, process.env.REACT_APP_TIMEZONE)),
    datasets: [
      {
        label: 'Sales',
        data: salesData,
        fill: true,
        borderColor: 'rgba(53, 162, 235, 0.7)',
        borderWidth: 3,
        backgroundColor: context => {
          const bgColor = ['rgba(53, 162, 235, 0.6)', 'rgba(53, 162, 235, 0.1)'];
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, bgColor[0]);
          gradientBg.addColorStop(1, bgColor[1]);
          return gradientBg;
        },
        tension: 0.4,
      },
      {
        label: 'Bookings',
        data: bookingsData,
        fill: true,
        borderColor: 'rgba(189, 64, 64, 0.7)',
        borderWidth: 3,
        backgroundColor: context => {
          const bgColor = ['rgba(189, 64, 64, 0.6)', 'rgba(189, 64, 64, 0.1)'];
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, bgColor[0]);
          gradientBg.addColorStop(1, bgColor[1]);
          return gradientBg;
        },
        tension: 0.4,
      },
    ],
  };

  // Lifecycle hooks
  useEffect(() => {
    const req = async () => {
      setLoading(true);
      try {
        const initialSalesData = [0, 0, 0, 0, 0, 0, 0];
        const initialBookinsData = [0, 0, 0, 0, 0, 0, 0];
        const before = msToMidnight(now + thisWeek[6] * 86400000) + 86400000;
        const after = msToMidnight(now + thisWeek[0] * 86400000);
        const prevWeekBefore = after;
        const prevWeekAfter = after - 604800000;

        const insights = await apiRequest({
          endpoint: 'insight',
          method: 'GET',
          params: `?after=${after}&before=${before}&select=price createdAt`,
        });
        const prevWeekInsights = await apiRequest({
          endpoint: 'insight',
          method: 'GET',
          params: `?after=${prevWeekAfter}&before=${prevWeekBefore}&select=price createdAt`,
        });

        if (insights.isError) throw new Error(insights.message);
        if (prevWeekInsights.isError) throw new Error(prevWeekInsights.message);
        if (!Array.isArray(insights) || !Array.isArray(prevWeekInsights)) throw new Error();

        // Set data for the Insights panel
        const totalSales = insights.reduce((total, item) => total + item.totalSaleAmount, 0);
        const prevWeekTotalSales = prevWeekInsights.reduce((total, item) => total + item.totalSaleAmount, 0);
        setTotalNumbers({
          // current week
          totalSales,
          totalBookings: insights.reduce((total, item) => total + item.totalSaleCount, 0),
          dailyAvrgSaleAmount: totalSales / 7,
          newClients: insights.reduce((clients, item) => clients.concat(item.newClients), []),
          // prev week
          prevWeekTotalSales,
          prevWeekTotalBookings: prevWeekInsights.reduce((total, item) => total + item.totalSaleCount, 0),
          prevWeekDailyAvrgSaleAmount: prevWeekTotalSales / 7,
          prevWeekNewClients: prevWeekInsights.reduce((clients, item) => clients.concat(item.newClients), []),
        });

        if (insights.length === 0) {
          setBookingsData([...initialBookinsData]);
          setSalesData([...initialSalesData]);
        }
        for (const insight of insights) {
          const bookingWeekday = msToDay(
            insight.date,
            0,
            process.env.REACT_APP_LOCALE_DATE,
            process.env.REACT_APP_TIMEZONE
          );

          data.labels.forEach((weekday, i) => {
            if (weekday === bookingWeekday) {
              initialSalesData[i] = insight.totalSaleAmount;
              initialBookinsData[i] = insight.totalSaleCount;

              setSalesData([...initialSalesData]);
              setBookingsData([...initialBookinsData]);
            }
          });
        }
        setLoading(false);
      } catch (e) {
        setTotalNumbers(totalNumbersInitialValue);
        setLoading(false);
      }
    };
    req();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisWeek]);

  useEffect(() => {
    try {
      setSalesDif(
        ((totalNumbers.totalSales - totalNumbers.prevWeekTotalSales) / totalNumbers.prevWeekTotalSales) * 100 || 0
      );
      setBookingsDif(
        ((totalNumbers.totalBookings - totalNumbers.prevWeekTotalBookings) / totalNumbers.prevWeekTotalBookings) *
          100 || 0
      );
      setDailyAvrgSaleAmountDif(
        ((totalNumbers.dailyAvrgSaleAmount - totalNumbers.prevWeekDailyAvrgSaleAmount) /
          totalNumbers.prevWeekDailyAvrgSaleAmount) *
          100 || 0
      );
      setNewClientsDif(
        ((totalNumbers.newClients.length - totalNumbers.prevWeekNewClients.length) /
          totalNumbers.prevWeekNewClients.length) *
          100 || 0
      );
    } catch (e) {
      setSalesDif(0);
      setBookingsDif(0);
      setDailyAvrgSaleAmountDif(0);
      setNewClientsDif(0);
    }
  }, [totalNumbers]);

  // JSX
  return (
    <div className='below-header page' style={{ minHeight: '100vh' }}>
      {isAuthenticated && (
        <>
          <h1 className='t-align-c no-m'>Insights</h1>
          <br />
          <section className='p-t-m'>
            <div className='booking-table-button-container'>
              <TextButton onClick={_ => setThisWeek([...thisWeek.map(num => num - 7)])}>Previous Week</TextButton>
              <h5
                className={
                  thisWeek[0] === 0 ? 'booking-table-current-time-range-current' : 'booking-table-current-time-range'
                }
              >
                {msToDateNoYr(now, thisWeek[0], process.env.REACT_APP_LOCALE_DATE, process.env.REACT_APP_TIMEZONE)} -{' '}
                {msToDateNoYr(now, thisWeek[6], process.env.REACT_APP_LOCALE_DATE, process.env.REACT_APP_TIMEZONE)}
              </h5>
              <TextButton onClick={_ => setThisWeek([...thisWeek.map(num => num + 7)])}>Next Week</TextButton>
            </div>
            <br />

            {loading ? (
              <Spinner color='primary' />
            ) : (
              <>
                <div className='custom-chart-contianer'>
                  <Row className='t-align-c'>
                    <Col md={12}>
                      <div className='custom-chart-wrapper'>
                        <h5>
                          {c.currency}
                          {totalNumbers.dailyAvrgSaleAmount.toFixed(2) || 0}
                          <span style={{ fontSize: 'small', color: dailyAvrgSaleAmountDif > 0 ? 'green' : 'red' }}>
                            {' '}
                            {dailyAvrgSaleAmountDif > 0 ? '+' : ''}
                            {dailyAvrgSaleAmountDif.toFixed(0)}%
                          </span>
                        </h5>
                        <div style={{ fontSize: 'small' }}>daily average</div>
                      </div>
                      <br />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6} style={{ position: 'relative' }}>
                      <div className='custom-chart-wrapper t-align-c'>
                        <h3>Earnings</h3>
                        <hr />
                        <h5>
                          {c.currency}
                          {totalNumbers.totalSales.toFixed(0)}{' '}
                          <span style={{ fontSize: 'small', color: salesDif > 0 ? 'green' : 'red' }}>
                            {' '}
                            {salesDif > 0 ? '+' : ''}
                            {salesDif.toFixed(0)}%
                          </span>
                        </h5>
                        <div style={{ fontSize: 'small' }}>weekly earnings</div>
                        <br />
                        <Line options={chartOptions} data={{ labels: data.labels, datasets: [data.datasets[0]] }} />
                      </div>
                      <br />
                    </Col>

                    <Col md={6} style={{ position: 'relative' }}>
                      <div className='custom-chart-wrapper t-align-c'>
                        <h3>Bookings</h3>
                        <hr />
                        <h5>
                          {totalNumbers.totalBookings.toFixed(0)}{' '}
                          <span style={{ fontSize: 'small', color: bookingsDif > 0 ? 'green' : 'red' }}>
                            {' '}
                            {bookingsDif > 0 ? '+' : ''}
                            {bookingsDif.toFixed(0)}%
                          </span>
                        </h5>
                        <div style={{ fontSize: 'small' }}>bookings</div>
                        <br />
                        <Line options={chartOptions} data={{ labels: data.labels, datasets: [data.datasets[1]] }} />
                      </div>
                      <br />
                    </Col>
                  </Row>

                  <div className='custom-chart-wrapper t-align-c'>
                    <h3>New Clients</h3>
                    <hr />
                    <h5>
                      {totalNumbers.newClients.length}
                      <span style={{ fontSize: 'small', color: newClientsDif > 0 ? 'green' : 'red' }}>
                        {' '}
                        {newClientsDif > 0 ? '+' : ''}
                        {newClientsDif.toFixed(0)}%
                      </span>
                    </h5>
                  </div>
                </div>
              </>
            )}
          </section>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({ isAuthenticated: state.user.isAuthenticated });

export default connect(mapStateToProps)(InsightsPage);
