///////////////////////////////////////////////////////////////////////////////////MODULES
import { Offcanvas, OffcanvasHeader, OffcanvasBody, Table } from '@ibiliaze/reactstrap';
import { msToHm } from '@ibiliaze/time';
//////////////////////////////////////////////////////////////////////////////////////////

const VisitUs = ({ isOpen, toggle, workingHours }) =>
  !!workingHours && (
    <Offcanvas direction='end' fade scrollable isOpen={isOpen} toggle={toggle}>
      <OffcanvasHeader toggle={toggle}>Working Hours</OffcanvasHeader>
      <OffcanvasBody>
        <Table borderless>
          <tbody>
            {Object.keys(workingHours).map((dow, i) => {
              const wh = workingHours[dow];
              return (
                <tr key={i}>
                  <th scope='row'>{dow}</th>
                  <td>{wh.start === wh.end ? 'Closed' : msToHm(wh.start)}</td>
                  <td>{wh.start === wh.end ? 'Closed' : msToHm(wh.end)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </OffcanvasBody>
    </Offcanvas>
  );

export default VisitUs;
