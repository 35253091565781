///////////////////////////////////////////////////////////////////////////////////MODULES
import { Col, Row } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Users = ({ selectedUser, users, onUserClick }) => (
  <div className='users t-align-c'>
    <Row>
      {!!users &&
        users.map((u, i) => (
          <Col key={i} className='user-container'>
            <div
              onClick={_ => onUserClick(u)}
              className={
                !!selectedUser && selectedUser?.username === u.username
                  ? 'user-avatar user-avatar-selected'
                  : 'user-avatar'
              }
            >
              <img src={u?.avatar} alt='avatar' />
              {!!selectedUser && selectedUser?.username === u.username && <div className='user-selected'>✔️</div>}
            </div>
            <div className='user-name'>{u.username}</div>
            <br />
          </Col>
        ))}
    </Row>
    <hr />
    {!!selectedUser ? <h6>Showing the details for {selectedUser?.username}</h6> : `Select your ${c.cosmetologistType}`}
  </div>
);

export default Users;
