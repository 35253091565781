///////////////////////////////////////////////////////////////////////////////////MODULES
import { FormText, Label, FormGroup, CustomInput, Col, Row } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const EarliestBooking = ({ days, hours, mins, onChange }) => (
  <FormGroup>
    <h4 className='t-align-c'>Book in advance</h4>
    <Row>
      <Col md={4}>
        <Label>Days</Label>
        <CustomInput name='days' value={days} onChange={onChange} type='number' />
      </Col>
      <Col md={4}>
        <Label>Hours</Label>
        <CustomInput name='hours' value={hours} onChange={onChange} type='number' />
      </Col>
      <Col md={4}>
        <Label>Minutes</Label>
        <CustomInput name='mins' value={mins} onChange={onChange} type='number' />
      </Col>
    </Row>
    <FormText>This determines the earliest booking a client can make from now.</FormText>
  </FormGroup>
);

export default EarliestBooking;
