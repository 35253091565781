///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  CustomInput,
  CustomButton,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  FormGroup,
  Label,
  ButtonGroup,
  TextButton,
  UncontrolledTooltip,
} from '@ibiliaze/reactstrap';
import QRCode from 'qrcode.react';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { cancelBooking } from '../../actions/bookings';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { copyToClipboard } from '../../utils/copyToClipboard';
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const CancelBookingForm = ({ user, selectedUser, cancelBooking }) => {
  // State
  const [inputs, setInputs] = useState({ email: '', bookingId: '' });
  const [qrText, setQrText] = useState(null);

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onCopyClick = _ => copyToClipboard(qrText);
  const onCancelClick = async _ => await cancelBooking(inputs);

  // Lifecycle hooks
  useEffect(() => {
    try {
      setQrText(
        window.location.host +
          window.location.pathname +
          `?user=${
            !!selectedUser && selectedUser.username
              ? selectedUser.username
              : !!user && !!user.user && !!user.user.username
              ? user.user.username
              : ''
          }`
      );
    } catch (e) {
      setQrText('');
    }
  }, [selectedUser, user]);

  // JSX
  return (
    <>
      <div style={{ float: 'right' }}>
        <CustomButton id='qr-gen' onClick={_ => {}} color='primary' size={c.b.s}>
          Generate QR Code
        </CustomButton>{' '}
        <CustomButton id='cancel-booking' onClick={_ => {}} color='danger' size={c.b.s}>
          Cancel a Booking
        </CustomButton>
      </div>
      <br />

      <UncontrolledPopover placement='top' target='qr-gen' trigger='legacy'>
        <PopoverHeader>
          <TextButton id='copy-share-link' onClick={onCopyClick}>
            Copy share link
          </TextButton>
        </PopoverHeader>
        <UncontrolledTooltip trigger='click' target='copy-share-link'>
          Copied
        </UncontrolledTooltip>
        <PopoverBody>{qrText && typeof qrText === 'string' && <QRCode value={qrText} />}</PopoverBody>
      </UncontrolledPopover>

      <UncontrolledPopover placement='top' target='cancel-booking' trigger='legacy'>
        <PopoverHeader>Cancel your booking</PopoverHeader>
        <PopoverBody>
          <FormGroup>
            <Label>Email *</Label>
            <CustomInput required name='email' value={inputs.email} onChange={onInputsChange} placeholder='Email' />
          </FormGroup>
          <FormGroup>
            <Label>Booking ID *</Label>
            <CustomInput
              required
              name='bookingId'
              value={inputs.bookingId}
              onChange={onInputsChange}
              placeholder='6359c843fea673f80d528f19'
            />
          </FormGroup>
          <ButtonGroup>
            <CustomButton color='danger' onClick={onCancelClick} size={c.b.s}>
              Continue
            </CustomButton>
            <CustomButton size={c.b.s}>Cancel</CustomButton>
          </ButtonGroup>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default connect(null, { cancelBooking })(CancelBookingForm);
