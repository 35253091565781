///////////////////////////////////////////////////////////////////////////////////MODULES
import axios from 'axios';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from './resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

export const api = axios.create({
  baseURL: resolveHost(),
  withCredentials: true,
  credentials: 'include',
});

const apiRequest = async ({ endpoint, method, params, data, headers }) => {
  try {
    const response = await api({
      method,
      url: `${resolveHost()}/api/${endpoint}${params ? params : ''}`,
      headers: headers || undefined,
      data: data && data,
    });

    return response.data;
  } catch (e) {
    return { isError: true, message: errorParser(e) };
  }
};

export const download = (name, data) => {
  const urlLocal = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = urlLocal;
  link.setAttribute('download', `${name}.txt`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export default apiRequest;
