///////////////////////////////////////////////////////////////////////////////////MODULES
import { connect } from 'react-redux';
import { Banner as BannerRS } from '@ibiliaze/reactstrap';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { ScaleIn } from '../Layout/Intersect';
//////////////////////////////////////////////////////////////////////////////////////////

const Banner = ({ settings }) =>
  !!settings &&
  settings?.global &&
  settings?.global?.showBanner && (
    <ScaleIn>
      <section className='page p-t-l p-b-l'>
        <BannerRS
          title={settings?.global?.bannerHeader}
          text={settings?.global?.bannerBody}
          style={{ background: settings?.global?.bannerColour, color: settings?.global?.bannerTextColour }}
        />
      </section>
    </ScaleIn>
  );

const mapStateToProps = state => ({ settings: state.settings });

export default connect(mapStateToProps)(Banner);
