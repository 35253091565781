///////////////////////////////////////////////////////////////////////////////////MODULES
import { CustomButton, InputGroup, FormGroup, FormText, CustomInput, Label, TextButton } from '@ibiliaze/reactstrap';
import { msToDtl } from '@ibiliaze/time';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const WishlistInputs = ({
  selectedTime = '',
  selectedUser,
  inputs,
  setInputs,
  onInputsChange,
  onSendConfirmationClick,
}) => (
  <>
    <FormGroup>
      <Label>Name *</Label>
      <CustomInput required name='clientName' placeholder='Name' value={inputs.clientName} onChange={onInputsChange} />
    </FormGroup>

    <FormGroup>
      <Label>Email *</Label>
      <CustomInput required name='email' placeholder='Email' value={inputs.email} onChange={onInputsChange} />
    </FormGroup>

    <FormGroup>
      <Label>One Time Passcode *</Label>
      <InputGroup>
        <CustomInput
          required
          name='code'
          placeholder='One Time Passcode'
          value={inputs.node}
          onChange={onInputsChange}
        />
        <CustomButton onClick={onSendConfirmationClick} color='success'>
          Send Code
        </CustomButton>
      </InputGroup>
      <FormText>Your One Time Passcode (OTP) can only be used once</FormText>
    </FormGroup>

    <FormGroup>
      <Label>Phone *</Label>
      <CustomInput
        required
        name='phone'
        type='tel'
        placeholder='Mobile'
        value={inputs.phone}
        onChange={onInputsChange}
      />
    </FormGroup>

    <FormGroup>
      <Label>Date of Birth *</Label>
      <CustomInput required name='dob' type='date' value={inputs.dob} onChange={onInputsChange} />
    </FormGroup>

    {selectedTime !== '' && (
      <FormGroup>
        <Label>Booking Time</Label>
        <CustomInput disabled required type='datetime-local' value={msToDtl(selectedTime)} onChange={_ => {}} />
      </FormGroup>
    )}

    <FormGroup>
      <Label>Payment Type</Label>
      <CustomInput
        required
        disabled={c.paymentTypes.length === 1 ? true : false}
        name='paymentType'
        type='select'
        value={inputs.paymentType}
        onChange={onInputsChange}
      >
        {c.paymentTypes.map((pt, i) => (
          <option key={i}>{pt}</option>
        ))}
      </CustomInput>
    </FormGroup>

    <FormGroup>
      <Label>Notes for {!!selectedUser && selectedUser?.username ? selectedUser.username : c.cust}</Label>
      <CustomInput
        placeholder='Notes'
        name='notes'
        type='textarea'
        rows='2'
        value={inputs.notes}
        onChange={onInputsChange}
      />
    </FormGroup>

    <FormGroup>
      <br />
      <CustomInput
        required
        type='checkbox'
        checked={inputs.ppChecked}
        onChange={e => setInputs(c => ({ ...c, ppChecked: e.target.checked }))}
      />{' '}
      I have read and agree to the{' '}
      <TextButton
        onClick={_ => {
          window.scrollTo({ top: 0 });
          window.open('/privacy-policy');
        }}
      >
        Privacy Policy,
      </TextButton>{' '}
      <TextButton
        onClick={_ => {
          window.scrollTo({ top: 0 });
          window.open('/terms-conditions');
        }}
      >
        Terms & Conditions,
      </TextButton>{' '}
      <TextButton
        onClick={_ => {
          window.scrollTo({ top: 0 });
          window.open('/return-policy');
        }}
      >
        Refund Policy
      </TextButton>
    </FormGroup>
  </>
);

export default WishlistInputs;
