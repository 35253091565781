/////////////////////////////////////////////////////////////////////////////////////TYPES
import {
  PUT_SETTINGS_FAILURE,
  PUT_SETTINGS_SUCCESS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,
  RESET_SETTINGS_SUCCESS,
  RESET_SETTINGS_FAILURE,
} from '../actions/types/types';
////////////////////////////////////////////////////////////////////SETTINGS DEFAULT STATE
const defaultSettings = {
  workingHours: {
    Mon: { start: 32400000, end: 61200000 },
    Tue: { start: 32400000, end: 61200000 },
    Wed: { start: 32400000, end: 61200000 },
    Thu: { start: 32400000, end: 61200000 },
    Fri: { start: 32400000, end: 61200000 },
    Sat: { start: 32400000, end: 61200000 },
    Sun: { start: 32400000, end: 61200000 },
  },
  timeoffs: [],
  interval: 1800000,
  earliestBooking: 0,
  roundBooking: 'up',
  type: 'global',
};
const settingsDefaultState = {
  global: defaultSettings,
  user: defaultSettings,
};
//////////////////////////////////////////////////////////////////////////SETTINGS REDUCER
const settings = (state = settingsDefaultState, action) => {
  switch (action.type) {
    // GET request
    case GET_SETTINGS_SUCCESS:
    case PUT_SETTINGS_SUCCESS:
    case RESET_SETTINGS_SUCCESS:
      return action.settings.type === 'global'
        ? { ...state, global: { ...action.settings } }
        : { ...state, user: { ...action.settings } };
    case PUT_SETTINGS_FAILURE:
    case GET_SETTINGS_FAILURE:
    case RESET_SETTINGS_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

export default settings;
