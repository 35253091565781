///////////////////////////////////////////////////////////////////////////////////MODULES
import { Pagination, PaginationItem, PaginationLink } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Paging = ({ page, onPageClick }) => (
  <Pagination size={c.p.s}>
    <PaginationItem>
      <PaginationLink onClick={e => onPageClick(e, 1)} first />
    </PaginationItem>
    <PaginationItem>
      <PaginationLink onClick={e => onPageClick(e, page - 1)} previous />
    </PaginationItem>
    <PaginationItem>
      <PaginationLink className='current-page' onClick={e => onPageClick(e, page)}>
        {page}
      </PaginationLink>
    </PaginationItem>
    <PaginationItem>
      <PaginationLink onClick={e => onPageClick(e, page + 1)}>{page + 1}</PaginationLink>
    </PaginationItem>
    <PaginationItem>
      <PaginationLink onClick={e => onPageClick(e, page + 2)}>{page + 2}</PaginationLink>
    </PaginationItem>
    <PaginationItem>
      <PaginationLink onClick={e => onPageClick(e, page + 1)} next />
    </PaginationItem>
  </Pagination>
);

export default Paging;
