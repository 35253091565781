///////////////////////////////////////////////////////////////////////////////////MODULES
import { FormGroup, CustomInput, Table } from '@ibiliaze/reactstrap';
import { msToHm } from '@ibiliaze/time';
//////////////////////////////////////////////////////////////////////////////////////////

const WorkingHours = ({ workingHours, onStartTimeChange, onEndTimeChange }) => (
  <>
    <h4 className='t-align-c'>Working hours</h4>
    <br />
    <FormGroup className='custom-chart-contianer'>
      <Table borderless>
        <thead>
          <tr>
            <th></th>
            <th>Starting Hours</th>
            <th>Ending Hours</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(workingHours).map((dow, i) => {
            const wh = workingHours[dow];
            return (
              <tr key={i}>
                <th scope='row'>{dow}</th>
                <td>
                  <CustomInput
                    value={msToHm(wh.start)}
                    onChange={e => onStartTimeChange(dow, e.target.value)}
                    type='time'
                    className='input-block'
                  />
                </td>
                <td>
                  <CustomInput
                    value={msToHm(wh.end)}
                    onChange={e => onEndTimeChange(dow, e.target.value)}
                    type='time'
                    className='input-block'
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </FormGroup>
  </>
);

export default WorkingHours;
