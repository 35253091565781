///////////////////////////////////////////////////////////////////////////////////MODULES
import {
  CustomButton,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  ButtonGroup,
} from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import WishlistInputs from './WishlistInputs';
//////////////////////////////////////////////////////////////////////////////////////////

const Callback = ({ toggle, modal, onSubmit, inputs, setInputs, onInputsChange, onSendConfirmationClick }) => (
  <Modal isOpen={modal} toggle={toggle}>
    <Form onSubmit={onSubmit}>
      <ModalHeader toggle={toggle}>Callback</ModalHeader>
      <ModalBody>
        <Label>Desired callback period</Label>
        <div className='t-align-c'>
          <ButtonGroup size={c.b.s}>
            {c.callback.callbackPeriods.map((period, i) => (
              <CustomButton
                key={i}
                onClick={_ => setInputs(c => ({ ...c, desiredCallbackPeriod: period }))}
                outline
                color='primary'
                active={inputs.desiredCallbackPeriod === period}
              >
                {period}
              </CustomButton>
            ))}
          </ButtonGroup>
        </div>
        <hr />

        <WishlistInputs
          inputs={inputs}
          setInputs={setInputs}
          onInputsChange={onInputsChange}
          onSendConfirmationClick={onSendConfirmationClick}
        />
      </ModalBody>
      <ModalFooter>
        <CustomButton color='primary' size={c.b.s}>
          Submit
        </CustomButton>
        <CustomButton onClick={toggle} size={c.b.s}>
          Close
        </CustomButton>
      </ModalFooter>
    </Form>
  </Modal>
);

export default Callback;
