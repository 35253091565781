export const env = process.env;

const c = {
  // GENERAL INFO
  cust: 'Faha Aesthetics',
  custSm: 'faha aesthetics',
  custJs: 'fahaAesthetics',
  slogan: 'Beauty. Standards.',
  phone1: '+447570695235',
  email1: 'fahaaesthetics@gmail.com',
  hqAddr: '4 Dereham Road, IG11 9HA, Barking, Essex',
  chatPerson: 'Faha',
  chatMessage: `Hello there! \n \nHow can we help?`,
  chatStatus: 'Typically replies within a few minutes',
  cosmetologistType: 'Practitioner',
  paymentTypes: ['card', 'cash'],
  currency: '£',
  darkMode: true,

  // MAIN PIC
  mainPic: {
    path: '/img/fam2.gif',
    logo: '/img/logo.png',
    buttonText: 'Book an Appointment',
    logoHeight: '10%',
    logoLeft: '20px',
    logoBottom: '20px',
    infoTop: '60%',
    imgBrightness: '.3',
    imgHeight: '500px',
    imgMobileHeight: '300px',
    titleLetterSpacing: '0.3rem',
    titleCentre: true,
    sloganCentre: true,
    mobileWidth: 700,
    shadow: {
      boxShadow: '0px 0px 200px 100px ',
      shadowColour: 'rgb(0 55 146) ',
    },
  },

  // SECONDARY PIC
  secondaryPic: {
    path: '/img/bikini.jpg',
    imgHeight: '500px',
    imgMobileHeight: '500px',
    imgBrightness: '0.4',
    shadow: {
      boxShadow: '0px 0px 200px 30px ',
      shadowColour: 'rgb(255 220 172) ',
    },
    slogan:
      "Aesthetics and beauty play a crucial role in enhancing one's confidence and self-image. At our beauty clinic, we believe that everyone deserves to feel their best, and that's why we strive to provide the highest quality aesthetic treatments.",
  },

  // SOCIALS
  socials: {
    instagram: 'fahaaesthetics',
    // facebook: 'fahaaesthetics',
    // twitter: 'fahaaesthetics',
  },

  // CALLBACK
  callback: {
    allowCallback: true,
    callbackPeriods: ['9am-12pm', '12pm-3pm', '3pm-6pm', '9pm-12am'],
  },

  // HEADER
  header: {
    blur: '1px',
    dark: true,
    styles: { height: '40px' },
    fadeOnPaths: ['/'],
  },

  // CAROUSEL
  carousel: {
    dark: true,
    fade: false,
    indicators: false,
  },

  // ABOUT US
  aboutUs: [
    {
      header: 'Laser Treatment',
      body: 'Suitable For All Skin Types, Both Men And Women.',
    },
    {
      header: 'Microneedling',
      body: "Helps stimulate your skin's healing process, so it produces more collagen and elastin",
    },
    {
      header: 'Electrolysis',
      body: 'Charged £10 per 10 minutes',
    },
  ],

  // REACTSTRAP BUTTONS
  b: {
    s: 'sm',
  },

  // REACTSTRAP PAGING
  p: {
    s: 'md',
  },
};

export default c;
