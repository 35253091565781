///////////////////////////////////////////////////////////////////////////////////MODULES
import { Col, Row, MainImage, CustomButton } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
import scroll from '../../utils/scroll';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { ScaleIn, TransformY, FadeIn } from '../Layout/Intersect';
//////////////////////////////////////////////////////////////////////////////////////////

const Copy = () => (
  <>
    <section className='page p-t-xl p-b-xl'>
      <FadeIn>
        <h1 className='t-align-c'>
          Book with <span className='text-gradient'>Mobile</span> and <span className='text-gradient'>Desktop</span>
        </h1>
      </FadeIn>
      <Row>
        <Col md={6}>
          <ScaleIn>
            <h3>
              <span className='text-gradient'>Easier</span> for You
            </h3>
            <p>
              With <b>{c.cust}</b>, customers have the option to easily book appointments either through their mobile
              device or desktop computer. On the go? Simply visit our mobile-friendly website and schedule your
              appointment in just a few taps. Prefer to use your desktop? No problem, visit our website and follow the
              simple steps to secure your appointment time. Either way, booking with <b>{c.cust}</b> has never been
              easier and more convenient.
            </p>
            <CustomButton onClick={_ => scroll(document.getElementById('services-section')?.offsetTop)} color='primary'>
              Choose your Service
            </CustomButton>
          </ScaleIn>
        </Col>
        <Col md={2}>
          <ScaleIn>
            <img alt='phone' src='/img/phone.png' style={{ width: '100%' }} />
          </ScaleIn>
        </Col>
        <Col md={4}>
          <ScaleIn>
            <img alt='phone' src='/img/surface.png' style={{ width: '100%' }} />
          </ScaleIn>
        </Col>
      </Row>
    </section>
    <div className='p-t-xl p-b-xl'>
      <TransformY>
        <MainImage
          imgPath={c.secondaryPic.path}
          imgHeight={c.secondaryPic.imgHeight}
          imgMobileHeight={c.secondaryPic.imgMobileHeight}
          imgBrightness={c.secondaryPic.imgBrightness}
          slogan={c.secondaryPic.slogan}
          boxShadow={c.secondaryPic.shadow.boxShadow}
          shadowColour={c.secondaryPic.shadow.shadowColour}
        />
      </TransformY>
    </div>
  </>
);

export default Copy;
