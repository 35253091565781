///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { CustomButton, FormGroup, FormText, ButtonGroup, UncontrolledTooltip } from '@ibiliaze/reactstrap';
import { connect } from 'react-redux';
import { messageParser } from '@ibiliaze/http-error-parser';
import { dtlToMs, roundMs, hmToMs } from '@ibiliaze/time';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { pushEmail } from '../../actions/email';
import { setAlert } from '../../actions/alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from '../../utils/httpRequest';
import { copyToClipboard } from '../../utils/copyToClipboard';
import c from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Actions = ({ pushEmail, setAlert, settings, selected, inputs }) => {
  // State
  const [paymentLink, setPaymentLink] = useState(null);

  // onClick functions
  const onSendEmailClick = async type => {
    await pushEmail({
      type,
      to: inputs?.email?.toLowerCase(),
      booking: {
        ...inputs,
        services: selected.map(s => s.value),
        duration: roundMs(hmToMs(inputs.duration), settings?.global?.roundBooking, settings?.global?.interval),
        bookingTime: roundMs(dtlToMs(inputs.bookingTime), 'nearest', settings?.global?.interval),
      },
    });
  };

  const onCreatePaymentLinkCreate = async sendEmail => {
    try {
      setPaymentLink(null);
      setAlert('Creating payment link', false, null, null, true);
      const res = await apiRequest({
        endpoint: 'booking/create-payment-link',
        method: 'POST',
        data: { ...inputs, sendEmail },
      });
      if (res.isError) throw new Error();

      setPaymentLink(res.url);
      setAlert(messageParser(res), false, null, null, false);
    } catch (e) {
      setPaymentLink(null);
      setAlert('Failed to create payment link', true, null, null, false);
    }
  };

  const onCopyClick = _ => {
    if (!!paymentLink && typeof paymentLink === 'string') copyToClipboard(paymentLink);
  };

  // JSX
  return (
    <>
      <h5 className='t-align-c'>Actions</h5>
      <FormGroup>
        <CustomButton onClick={_ => onSendEmailClick('newBooking')} color='primary' size={c.b.s}>
          Send new booking email
        </CustomButton>
      </FormGroup>
      <FormGroup>
        <CustomButton onClick={_ => onSendEmailClick('bookingReminder')} color='primary' size={c.b.s}>
          Send booking reminder email
        </CustomButton>
        <br />
        <FormText>Sent automatically on the day of the booking at midnight</FormText>
      </FormGroup>
      <FormGroup>
        <CustomButton onClick={_ => onSendEmailClick('paymentReminder')} color='primary' size={c.b.s}>
          Send payment reminder email
        </CustomButton>
        <br />
        <FormText>Sent automatically every missed day at midnight</FormText>
      </FormGroup>
      <FormGroup>
        <ButtonGroup>
          <CustomButton onClick={async _ => onCreatePaymentLinkCreate()} color='primary' size={c.b.s}>
            Create payment link
          </CustomButton>
          {!!paymentLink && typeof paymentLink === 'string' && (
            <>
              <CustomButton id='copy-payment-link' onClick={onCopyClick} color='success' size={c.b.s}>
                Copy
              </CustomButton>
              <UncontrolledTooltip trigger='click' target='copy-payment-link'>
                Copied
              </UncontrolledTooltip>
            </>
          )}
        </ButtonGroup>
      </FormGroup>
      <FormGroup>
        <CustomButton onClick={async _ => onCreatePaymentLinkCreate(true)} color='primary' size={c.b.s}>
          Send payment link via email
        </CustomButton>
      </FormGroup>
    </>
  );
};

export default connect(null, { pushEmail, setAlert })(Actions);
