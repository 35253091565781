///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/settings
export const getSettingsSuccess = settings => ({ type: types.GET_SETTINGS_SUCCESS, settings });
export const getSettingsFailure = errorMessage => ({ type: types.GET_SETTINGS_FAILURE, errorMessage });
export const getSettings = endpoint => async dispatch => {
  try {
    const res = await api.get(!endpoint ? `${resolveHost()}/api/settings` : `${resolveHost()}/api/settings${endpoint}`);

    dispatch(getSettingsSuccess(res.data.settings));
  } catch (e) {
    dispatch(getSettingsFailure(e.message));
  }
};

// GET /api/settings/reset
export const resetSettingsSuccess = settings => ({ type: types.RESET_SETTINGS_SUCCESS, settings });
export const resetSettingsFailure = errorMessage => ({ type: types.RESET_SETTINGS_FAILURE, errorMessage });
export const resetSettings = endpoint => async dispatch => {
  try {
    dispatch(setAlert('Resettings the settings...', false, null, null, true));
    const res = await api.get(
      !endpoint ? `${resolveHost()}/api/settings/reset` : `${resolveHost()}/api/settings/reset${endpoint}`
    );

    dispatch(setAlert(res.data.message, false, res.status, types.RESET_SETTINGS_SUCCESS));
    dispatch(resetSettingsSuccess(res.data.settings));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.RESET_SETTINGS_FAILURE));
    dispatch(resetSettingsFailure(e.message));
  }
};

// PUT /api/settings
export const putSettingsSuccess = settings => ({ type: types.PUT_SETTINGS_SUCCESS, settings });
export const putSettingsFailure = errorMessage => ({ type: types.PUT_SETTINGS_FAILURE, errorMessage });
export const putSettings = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the settings...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/settings/${data.settingsId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_SETTINGS_SUCCESS));
    dispatch(putSettingsSuccess(res.data.settings));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.PUT_SETTINGS_FAILURE));
    dispatch(putSettingsFailure(e.message));
  }
};
