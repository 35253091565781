///////////////////////////////////////////////////////////////////////////////////MODULES
import { useInView } from 'react-intersection-observer';
//////////////////////////////////////////////////////////////////////////////////////////

export const TransformY = ({ children }) => {
  // Refs
  const [ref, inView] = useInView({ triggerOnce: true });

  // JSX
  return (
    <div ref={ref} className={`intersect intersect-translate-y ${inView ? 'visible' : ''}`}>
      {children}
    </div>
  );
};

export const ScaleIn = ({ children }) => {
  // Refs
  const [ref, inView] = useInView({ triggerOnce: true });

  // JSX
  return (
    <div ref={ref} className={`intersect intersect-scale-in ${inView ? 'visible' : ''}`}>
      {children}
    </div>
  );
};

export const ScaleOut = ({ children }) => {
  // Refs
  const [ref, inView] = useInView({ triggerOnce: true });

  // JSX
  return (
    <div ref={ref} className={`intersect intersect-scale-out ${inView ? 'visible' : ''}`}>
      {children}
    </div>
  );
};

export const FadeIn = ({ children }) => {
  // Refs
  const [ref, inView] = useInView({ triggerOnce: true });

  // JSX
  return (
    <div ref={ref} className={`intersect intersect-fade-in ${inView ? 'visible' : ''}`}>
      {children}
    </div>
  );
};
